import ImgConsumption from "../images/app_campaign/illustration-consumption.svg";
import ImgFlower from "../images/app_campaign/illustration-flower.svg";
import ImgFuelBack from "../images/app_campaign/illustration-fuelback.svg";
import ImgFueling from "../images/app_campaign/illustration-fueling.svg";
import ImgLoyaltyDiscounts from "../images/app_campaign/illustration-loyalty-discounts.svg";
import ImgShop from "../images/app_campaign/illustration-shop.svg";
import ImgSmartElectricity from "../images/app_campaign/illustration-smart-electricity.svg";

export const AppCampaignIllustrationsMap = {
    'illustration-consumption': ImgConsumption,
    'illustration-flower': ImgFlower,
    'illustration-fuelback': ImgFuelBack,
    'illustration-fueling': ImgFueling,
    'illustration-loyalty-discounts': ImgLoyaltyDiscounts,
    'illustration-shop': ImgShop,
    'illustration-smart-electricity': ImgSmartElectricity,
};