import {
    StartPath,
    InvoicesPath,
    SettingsPath,
    ContractsPath,
    CardsPath,
    CommunityPath,
    FuelTaxiOrderPath,
    TransactionsPath,
    ServicePath,
    StationsPath,
    ConsumptionPath,
    ProductionPath,
    NordpoolPath,
    PaymentsPath,
    StatisticsPath,
    TranslationSettingsPath,
    StockPriceAlertsPath,
    AuthorizationsPath,
    NotificationsPath,
    UserManagementSettingsPath,
    MarketingNotificationsPath,
    HintHistoryPath,
    BasketballCommunityPath,
    SummerCampaignPath,
    AppCampaignsPath
} from "./routes";
import auth from "../auth/authenticate";
import { IsLatvianWeb } from "../helpers/AlternativeDesign";
import {LotteryCampaign2024} from "./featureFlags";

export const PrimaryMenuItem = {
    HomePage: "HomePage",
    PrivateCustomer: "PrivateCustomer",
    PrivateCustomerLv: "PrivateCustomerLv",
    BusinessCustomer: "BusinessCustomer",
};

export const getMenu = () => {
    if (IsLatvianWeb()) {
        return auth.isCompany()
            ? MenuDesktop.BusinessCustomer
            : MenuDesktop.PrivateCustomerLv;
    }

    return auth.isCompany()
        ? MenuDesktop.BusinessCustomer
        : MenuDesktop.PrivateCustomer;
}

const MenuDesktop = {
    PrivateCustomer: [
        {
            path: StartPath,
            translationKey: "Menu.Home"
        },
        {
            path: ServicePath,
            translationKey: "Menu.Service",
            children: [
                {
                    path: ServicePath,
                    translationKey: "Menu.ServiceOverview",
                },
                {
                    path: InvoicesPath,
                    translationKey: "Menu.Invoices"
                },
                {
                    path: TransactionsPath,
                    translationKey: "Menu.Transactions"
                },
                {
                    path: CardsPath,
                    translationKey: "Menu.Cards"
                },
                {
                    path: ContractsPath,
                    translationKey: "Menu.Contracts"
                },
                {
                    path: ConsumptionPath,
                    translationKey: "Menu.Consumption"
                },
                {
                    path: ProductionPath,
                    translationKey: "Menu.Production"
                },
                {
                    path: NordpoolPath,
                    translationKey: "Menu.Nordpool"
                },
                {
                    path: FuelTaxiOrderPath,
                    translationKey: "Menu.FuelTaxiOrderForm"
                }
            ]
        },
        {
            path: StationsPath,
            translationKey: "Menu.Stations"
        },
        {
            path: null,
            translationKey: "Menu.Community",
            children: [
                {
                    path: CommunityPath,
                    translationKey: "Menu.RefuelAndPlantTrees"
                },
                {
                    path: BasketballCommunityPath,
                    translationKey: "Menu.BasketballCommunity"
                }
            ]
        },
        {
            path: SummerCampaignPath,
            translationKey: "Menu.SummerCampaign",
            featureFlag: LotteryCampaign2024
        },
        {
            path: null,
            translationKey: "Menu.Account",
            isDisplayed: false,
            children: [
                {
                    path: SettingsPath,
                    translationKey: "Menu.Settings",
                    children: [
                        {
                            path: TranslationSettingsPath,
                            translationKey: "Menu.TranslationSettings"
                        }
                    ]
                },
                {
                    path: StockPriceAlertsPath,
                    translationKey: "Menu.StockPriceAlerts"
                },
                {
                    path: UserManagementSettingsPath,
                    translationKey: "Menu.UserManagement"
                },
                {
                    path: MarketingNotificationsPath,
                    translationKey: "Menu.MarketingNotifications"
                },
                {
                    path: AuthorizationsPath,
                    translationKey: "Menu.Authorizations"
                },
                {
                    path: HintHistoryPath,
                    translationKey: "Menu.HintHistory"
                },
                {
                    path: AppCampaignsPath,
                    translationKey: "Menu.AppCampaigns"
                }
            ]
        },
        {
            path: NotificationsPath,
            translationKey: "Menu.Notifications",
            isDisplayed: false
        }
    ],
    PrivateCustomerLv: [
        {
            path: StartPath,
            translationKey: "Menu.Home"
        },
        {
            path: ServicePath,
            translationKey: "Menu.Service",
        },
        {
            path: InvoicesPath,
            translationKey: "Menu.Invoices"
        },
        {
            path: ContractsPath,
            translationKey: "Menu.Contracts"
        },
        {
            path: null,
            translationKey: "Menu.Account",
            isDisplayed: false,
            children: [
                {
                    path: SettingsPath,
                    translationKey: "Menu.Settings",
                    children: [
                        {
                            path: TranslationSettingsPath,
                            translationKey: "Menu.TranslationSettings"
                        }
                    ]
                },
                {
                    path: StockPriceAlertsPath,
                    translationKey: "Menu.StockPriceAlerts"
                },
                {
                    path: UserManagementSettingsPath,
                    translationKey: "Menu.UserManagement"
                },
                {
                    path: MarketingNotificationsPath,
                    translationKey: "Menu.MarketingNotifications"
                },
                {
                    path: AuthorizationsPath,
                    translationKey: "Menu.Authorizations"
                }
            ]
        },
        {
            path: NotificationsPath,
            translationKey: "Menu.Notifications",
            isDisplayed: false
        }
    ],
    BusinessCustomer: [
        {
            path: StartPath,
            translationKey: "Menu.Home"
        },
        {
            path: ServicePath,
            translationKey: "Menu.Service",
            children: [
                {
                    path: ServicePath,
                    translationKey: "Menu.ServiceOverview",
                },
                {
                    path: InvoicesPath,
                    translationKey: "Menu.Invoices"
                },
                {
                    path: TransactionsPath,
                    translationKey: "Menu.Transactions"
                },
                {
                    path: CardsPath,
                    translationKey: "Menu.Cards"
                },
                {
                    path: PaymentsPath,
                    translationKey: "Menu.Payments"
                },
                {
                    path: ContractsPath,
                    translationKey: "Menu.Contracts"
                },
                {
                    path: ConsumptionPath,
                    translationKey: "Menu.Consumption"
                },
                {
                    path: ProductionPath,
                    translationKey: "Menu.Production"
                },
                {
                    path: NordpoolPath,
                    translationKey: "Menu.Nordpool"
                },
                {
                    path: FuelTaxiOrderPath,
                    translationKey: "Menu.FuelTaxiOrderForm"
                },
                {
                    path: StatisticsPath,
                    translationKey: "Menu.Statistics",
                }
            ]
        },
        {
            path: StationsPath,
            translationKey: "Menu.Stations"
        },
        {
            path: CommunityPath,
            translationKey: "Menu.Community"
        },
        {
            path: null,
            translationKey: "Menu.Account",
            isDisplayed: false,
            children: [
                {
                    path: SettingsPath,
                    translationKey: "Menu.Settings",
                    children: [
                        {
                            path: TranslationSettingsPath,
                            translationKey: "Menu.TranslationSettings"
                        }
                    ]
                },
                {
                    path: StockPriceAlertsPath,
                    translationKey: "Menu.StockPriceAlerts"
                },
                {
                    path: UserManagementSettingsPath,
                    translationKey: "Menu.UserManagement"
                },
                {
                    path: MarketingNotificationsPath,
                    translationKey: "Menu.MarketingNotifications"
                },
                {
                    path: AuthorizationsPath,
                    translationKey: "Menu.Authorizations"
                },
                {
                    path: HintHistoryPath,
                    translationKey: "Menu.HintHistory"
                },
            ]
        },
        {
            path: NotificationsPath,
            translationKey: "Menu.Notifications",
            isDisplayed: false
        }
    ],
};
